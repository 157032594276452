import React from "react"
import {Avatar, Tag, Row, Col, Card} from "antd"
import {Colors} from "../../Colors"
import {useMediaQuery} from "react-responsive"

function UserCard(props) {
const {Meta} = Card

const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
//const score= "8"

    return(
 
        <Card style={isMobile ? { width: 300, margin: 12,  borderRadius: '5px' } : { width:320, margin: 14,  borderRadius: '5px' }} >
        <Row gutter={16}>
        <Col span={18} offset={1}>
            <Meta
            avatar={
                    
                <Avatar
                      style={{   backgroundColor: `${Colors.primary}`}}
                      size={50}
                         >
                    {<IconNameGenerator user={props.name}/>}
                 </Avatar>   
            
            }
            title={<span className="font-weight-bold text-black" style={{fontSize:'0.9rem'}}>{props.name}</span>}
            description={<span className="font-weight-bold" style={{fontSize:'1rem'}}>{props.score}</span>}

            />
        </Col>
        <Col span={3}>
            <Tag className="mt-1 pt-1"  color={Colors.secondary} style={(props.rank).toString().length <= 4 ? {minWidth:'3rem'} : '' }>
                <h6 className="d-flex align-items-center justify-content-center text-white font-weight-bold">
               {props.rank}
                </h6>
            </Tag>
        </Col>

        </Row>
      </Card>
      
    )
}

export default UserCard


const IconNameGenerator = ({user}) => {
    let str = ""
        if(user.includes(" ")){
            let x = user.split(" ")
           // console.log(x)    
            for(let i=0; i<x.length; i++){
               str +=  x[i].toString().toUpperCase().slice(0,1)  
            }
            return str
        } 
        else{
            return user.slice(0,1).toUpperCase()
        }
    }

    