import React from 'react'
import Particles from 'react-particles-js'

function particleBg() {
   
    return (
        <div style={{position: "absolute",
          width: '100%',
          height: "100vh",
          left:0,
          top:0,   
          zIndex: -5,
          backgroundColor: 'rgba(229, 229, 229, 0.4)',
        }}>
            <Particles style={{position: 'absolute', top:'100px',backgroundColor: 'rgba(229, 229, 229, 0.7)'}}
                params={{
                    particles: {
    number: {
      value: 58,
      density: {
        enable: true,
        value_area: 800.8832963014578
      }
    },
    color: {
      value: "#008384"
    },
    shape: {
      type: "circle",
      stroke: {
        width: 2,
        color: "#008384"
      },
      polygon: {
        enable: true,  
        nb_sides: 6
      },
      image: {
        src: "",
        width: 100,
        height: 100
      }
    },
    opacity: {
      value: 0.5603036688052901,
      random: false,
      anim: {
        enable: false,
        speed: 0.4,
        opacity_min: 0.1,
        sync: false
      }
    },
    size: {
      value: 2,
      random: true,
      anim: {
        enable: false,
        speed: 20,
        size_min: 0.1,
        sync: false
      }
    },
    line_linked: {
      enable: true,
      distance: 299.88083682536654,
      color: "#008384",
      opacity: 0.2156640387635437,
      width: 1.6313280775270874
    },
    move: {
      enable: true,
      speed: 2,
      direction: "none",
      random: false,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200
      }
    }
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: {
        enable: false,
        mode: "repulse"
      },
      onclick: {
        enable: false,
        mode: "push"
      },
      resize: true
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1
        }
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 3,
        speed: 3
      },
      repulse: {
        distance: 200,
        duration: 0.4
      },
      push: {
        particles_nb: 4
      },
      remove: {
        particles_nb: 2
      }
    }
  },
  retina_detect: true
                }} />
        </div>
    )
}

export default particleBg
