import React,{useState, useEffect} from 'react'
import "./map.css"
import map from "assets/mapathon.svg"
import locate from "assets/location.svg"
import {SyncOutlined} from "@ant-design/icons"


function MapLogoAnime() {
    const [show, setShow] = useState(false)

        if(!show){ //fake component to load assets before rendering
           return(
                <div className="box">
                 <img src={map} 
                        onLoad = {() => setShow(true)}
                        onError = {() => setShow(false)}
                        style={{opacity:0}}
                    />    
                     <img src={locate} 
                            onLoad = {() => setShow(true)}
                            onError = {() => setShow(false)}
                            style={{opacity:0}}
                        />   

                    <SyncOutlined spin/>   
            </div>
        )}

        else{
        return (
            <div className="box">
             <div className="map">
              <img src={map} style={{width: '8.5rem'}}/>     
                 <div className="logo">
                     <img src={locate} style={{width: '1rem'}}/>  
                       
                </div>

                <div className="circle"></div>
             </div>
            
            </div>
           
        )
        }
   
}

export default MapLogoAnime
