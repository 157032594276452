import React, {useContext} from 'react'
import {useLocation} from "react-router-dom"
import {Button, Row, Col } from 'antd'
import jsonQ from "jsonq"
import axios from "axios"
import WinnersPanel from "../components/WinnersPanel"
import Navbar from "../components/Navbar"
import ListGroup from "../components/ListGroup"
import Header from "../components/header"
import Preloader from "../components/Preloader"
import ErrorPage from "../components/ErrorPage"
import {ListContext} from "../ListContext"
import {Colors} from "../Colors"
import Bg from "../components/particleBg"
import {useMediaQuery} from "react-responsive"
import { data1 } from "../data1"

function Main() {
    const isMobile = useMediaQuery({
        query: '(max-width: 500px)'
      })

 const {data , load, error} =  useContext(ListContext)
 const [dataValue, setdataValue] = data
 const [loadValue, setloadValue] = load
 const [errorValue, setErrorValue] = error 
/*const {pathname} = useLocation()
var get_url =  pathname     //to obtain path from browser window
var data_slicer = get_url.slice(11,)    //remove the base url i.e landing page (/dashboard)
var data_campaign = data_slicer.split("/")  //convert string to array for selecting individual parameters

//https://my-json-server.typicode.com/AshiqMehmood/demo/
//https://mapathon.icfoss.org/LeaderBoard/api/campaign_details/

var results = score
const sorted_data = listdata.sort(GetSortOrder("score")) //sorted  list of whole team data
*/

const WinnerSection = ({data}) => (
    <div className="m-2 mt-4 pt-"
     style={{backgroundColor: '#FFFF', minHeight: '350px', borderBottom: '8px solid', borderColor:`${Colors.primary}`, borderBottomLeftRadius:'5px', borderBottomRightRadius: '5px'}}>
    <Row gutter={[10, {xs: 16, md: 16}]} justify="center" align="center" className="mt-4 pt-4 mb-2 pb-2">
        <Col className="gutter-row" >
            {data.map((item, index) => {
                if(validator(item.score, data) === 1 ){
                    return(
                        <WinnersPanel 
                            key={index}
                            name={item.display_name}
                            score={item.score}
                            rank={validator(item.score, data)}
                           
                        />
                    )
                }
            })}
        </Col>
        <Col  className="gutter-row">
        {data.map((item,index) => {
                if(validator(item.score, data) === 2 ){
                    return(
                        <WinnersPanel 
                            key={index}
                            name={item.display_name}
                            score={item.score}            
                            rank={validator(item.score, data)}/>

                    )
                }
            })}
        </Col>
        <Col  className="gutter-row">
        {data.map((item,index) => {
                if(validator(item.score, data) === 3 ){
                    return(
                        <WinnersPanel
                            key={index} 
                            name={item.display_name}
                            score={item.score}
                            rank={validator(item.score, data)}
                           
                        />
                    )
                }
            })}
        </Col>
    </Row>
 
</div>)

function validator(point, data){ //function returns the position of incoming scores & allows for future conditions
  let sdata = jsonQ(data)
   let filtered_data = sdata.find('score')
  let arr = filtered_data.value()
  let index_arr = arr.indexOf(point) + 1
  return index_arr

}


     if(loadValue){
            return (
                <div style={{backgroundColor: 'rgba(229, 229, 229, 0.6)'}}>
                 
                 <div className="container-fluid" style={{height: "100%"}}>
                 <Navbar />
                    <Header />
                    
                <Row >
                
                    <Col xs={{span:24}} lg={{span: 13}}  md={{span:24}} xl ={{span: 15}} xxl={{span: 14}} className="mt-4 pt-3 ml-2">
                            <h4 className="font-weight-bold text-black m-2 p-2">Top Achievers</h4>
                            <WinnerSection data = {dataValue} />
                        </Col>
                     <Col xs={{span:18}} lg={{span: 7, offset: 1}} md={{span:24}} xl={{span:7}} xxl={{span:7, offset:1}} className="mt-4 pt-3 mr-2">
                            <h4 className="font-weight-bold text-black m-2 p-2">All Members</h4> 
                            <ListGroup/>
                      </Col>
                </Row>
            
         </div>
         {isMobile ? ' ' : <Bg />}
      </div>
            )
        }
        else if(errorValue){
            return <ErrorPage />
        }

        else {
            return <Preloader />
        }
      
    


       
           
}


export default Main  

