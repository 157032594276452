import React, {useContext, useState} from "react"
import {Col, Row, Button, Tooltip} from "antd"
import logo from "assets/mapathon_log.svg"
import {AlignRightOutlined, CloseOutlined} from "@ant-design/icons"
import {useMediaQuery} from "react-responsive"
import {ListContext} from "../ListContext"

function Navbar() {
    const isMobile = useMediaQuery({
        query: '(max-width: 500px)'
      })
    
      const {isEmbed} = useContext(ListContext)
      const [isEmbedValue, setIsEmbedValue] = isEmbed
  

    return(
        <div>
            <Row gutter={{xs: 40, sm: 32 , md: 32, lg: 24, xl: 16 }} >
                <Col xs={8} sm={8}  md={7} lg={4} xl={2} xxl={3}>
                    <img  src={logo} style={{width:'calc(110px + 1vw)'}} className="mt-2 mb-2"/>
                </Col>
                <Col xs={{span:10, offset:1}} sm={10} md={9} lg={6} xl={5} xxl={4}>
                    <p className="font-weight-bold mt-2 pt-2 d-flex align-items-center" style={{fontSize:'calc(19px + 1vw)', color:'#333333'}} >Leaderboard</p>
                </Col>
                <Col xs={{span:2, offset: 2}} sm={{span:1, offset:4}} md={{span:2}} lg={{span:1, offset:14}} xl={{span:1, offset:14}} xxl={{span:1, offset:14}}  className="d-flex justify-content-end mt-2 pt-3">
                     { isEmbedValue ?
                        <Tooltip title="Back">
                           <CloseOutlined style={{fontSize: 'calc(12px + 1vw)', color:'#333333'}}
                                onClick={() => setIsEmbedValue(!isEmbedValue)}
                            />
                        </Tooltip> 

                        :<AlignRightOutlined style={{fontSize: 'calc(12px + 1vw)', color:'#333333'}} />
                     } 
                     
                </Col>
            </Row>
        </div>
    )
}

export default Navbar