import React, {useState} from 'react'
import {Card, Avatar, Badge} from 'antd'
import dp from "assets/profile.svg"
import gold from "assets/crown-g.svg"
import silver from "assets/crown-s.svg"
import bronze from "assets/crown-b.svg"
import {Colors} from "../Colors"

const { Meta } = Card

function WinnersPanel(props) {
    
    
    return (
        <div>
        <Card style={{ width: 260, border:'1px solid', borderColor: `${Colors.primary}` ,borderRadius: '5px'}} >
            <Meta 
                avatar={
                    <span className="avatar-item">
                        <Badge count={props.rank} style={{backgroundColor: `${Colors.secondary}`}}>
                         <Avatar
                            size={56}
                            src={dp}
                            style={{
                                border:'2px solid',
                                borderColor: `${Colors.primary}`,
                                backgroundColor: `${Colors.base}`,
                                
                                
                            }}
                         />
                        
                        </Badge>
                        </span>

                }
                title={<span className="font-weight-bold text-black" style={{fontSize:'0.9rem'}}>{props.name}</span>}
                description=
                { props.rank === 1
                ? <Trophy img={gold} point={props.score}/>
                    : props.rank ===  2 ? <Trophy img={silver} point={props.score} />
                    : <Trophy img={bronze} point={props.score} />
            
                 }
                

               
            />
           

        </Card>
        </div>
    )

}

export default WinnersPanel


const Trophy = ({col , point, img}) => (
    <div className="row">
        <div className="col-8 font-weight-bold">
         {point}
        </div>
        <div className="col-2 mt-2 pt-2">
             <img src={img}  style={{width: '1.4rem' }}  />
        </div>    
    </div>
    
)

const ProfileIcon = () => (
    <div className="d-flex align-items-center justify-content-center" style={{border:'2px solid #009973', width:'4rem', borderRadius:'50%' }}>
        <img src={dp} className="rounded-circle"/>
    </div>
)

