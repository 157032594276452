import React, {useState, createContext, useEffect} from "react"
import axios from "axios"

export const ListContext = createContext()

export const DataProvider = (props) => {
    const [data , setData] = useState([])
    const [load, setLoad] = useState(false)
    const [isEmbed, setIsEmbed] = useState(false)
    const [error, setError] = useState(false)
 //https://mapathon.icfoss.org/LeaderBoard/api/campaign_details/test-campaign
 //'https://my-json-server.typicode.com/AshiqMehmood/demo/campaign'
 //https://leaderboard.mapmykerala.in/LeaderBoard/api/campaign_details/test-campaign
  
    useEffect(() => {
        axios({
          method: 'get',
          baseURL: 'https://leaderboard.mapmykerala.in/LeaderBoard/api/campaign_details/test-campaign',
          timeout: '10000'
        })  
        .then(response => {
          //console.log(response.data)
          //console.log(Object.keys(response.data)[0])
          const sorted_data = response.data.sort(GetSortOrder("score"))
          setData(sorted_data)
          //setCampaign(Object.keys(response.data)[0])
          setLoad(true)
          
          
        })
        .catch(err => {
         //console.log(err) 
         setError(true)
        })
       }, [])


    
       return(
        <ListContext.Provider value={
          {data: [data, setData],
             load: [load, setLoad], 
             isEmbed: [isEmbed, setIsEmbed],
             error: [error, setError]
            }
          
          }>
            {props.children}
        </ListContext.Provider>
    )



    function GetSortOrder(prop){ //sort json data in Descending order
        return function(a,b){
            if(a[prop] > b[prop]){
                return -1
            }
            else if(a[prop] < b[prop]){
                return 1
            }
            return 0
        }
      }  
       
}