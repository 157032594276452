import React, {useContext} from 'react'
import { List, Avatar, Tag} from 'antd';
import jsonQ from "jsonq"
import { UserOutlined} from '@ant-design/icons';
import {Colors} from "../Colors"
import profile from "assets/profile.svg"
import {ListContext} from "../ListContext"
import {useMediaQuery} from "react-responsive"
import {CountUp} from 'react-countup'

function ListGroup(props){

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })

  const {data , load} = useContext(ListContext) //import api fetch data from ListContext
  const [dataValue, setDataValue] = data

  var dataValue_filtered = [] //return list containing members othere than top 3
  dataValue.forEach((item) => {
      if(validator(item.score, dataValue) > 3){
        dataValue_filtered.push(item)
      }
    return dataValue_filtered
    }
  ) 
 // console.log(dataValue_filtered)

 function validator(point, data){ //function returns the position of incoming scores & allows for future conditions
  let sdata = jsonQ(data)
   let filtered_data = sdata.find('score')
  let arr = filtered_data.value()
  let index_arr = arr.indexOf(point) + 1
  return index_arr

}

return(
    
    <List style={isMobile ? {width:340, marginTop: 16, backgroundColor: '#FFFF', border: '1px solid #F0F6F6', borderRadius: '5px'}: { marginTop: 20, backgroundColor: '#FFFF', border: '1px solid #F0F6F6', borderRadius: '5px'} }
    itemLayout="vertical"
    size="small"
    pagination={{
      onChange: page => {
        console.log(page);
      },
      pageSize: 6,
    }}
    dataSource={dataValue} //use dataValue_filtered to remove top 3 members from list
  
    renderItem={item => (
      <List.Item
        key={item.id}
        extra={
            
                <Tag className="mt-1 pt-1" color={Colors.base} style={ (validator(item.score, dataValue)).toString().length <= 4 ? {minWidth:'3rem'} : '' }>
                    <h6 className="d-flex align-items-center justify-content-center font-weight-bold"
                        style={{color: `${Colors.primary}`}}
                    >
                   {validator(item.score, dataValue)}
                    </h6>
                </Tag>
                
        }
      >
        <List.Item.Meta

          avatar={<Avatar 
                 size={50}
                 src={profile}
                 style={{
                        backgroundColor: `${Colors.base}`,
                        color: `${Colors.primary}`,
                        fontSize: '1.6rem',
                        
                    }}
            //icon={ }

            /> }
          title={<span className="font-weight-bold text-black" style={{fontSize:'0.9rem'}}>{item.display_name}</span>}
          description={<span className="font-weight-bold text-black" style={{fontSize:'1rem'}}>{item.score}</span>}
        />
       
      </List.Item>
    )}
  />
   

)

    }

    export default ListGroup