import React from "react"
import {Card, Col, Row} from "antd"
import {TeamOutlined, UserOutlined } from "@ant-design/icons"
import {useMediaQuery} from "react-responsive"

function CampDetails(props){
const {Meta} = Card

const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })

    return(
       
       <Card style={isMobile ? { width: 145,marginBottom:10, border:'1px solid #e6e6e6', borderRadius: '5px',backgroundColor: 'transparent' }:{ width: 200, margin: 15, border:'1px solid #e6e6e6', borderRadius: '5px',backgroundColor: 'transparent' }} >
            <Row>
            <Col xl={16} xs={20}>
                <Meta
                title={<span className="text-white" style={isMobile ? {fontSize:'0.65rem'}:  {fontSize:'0.9rem'}}>{props.title}</span>}
                description={<span className="text-white font-weight-bold" style={isMobile ? {fontSize:'0.8rem'} :  {fontSize:'1rem'}}>{props.description}</span>}

                />
            </Col>
            <Col xl={{span:2, offset:5}} xs={{span:3, offset:1}} >
                {props.title === "Team" ? <TeamOutlined  style={{ fontSize: '1.2rem', color: '#FFFF'}}/>
                    : <UserOutlined style={{ fontSize: '1.2rem', color: '#FFFF'}}/>
                }
            </Col>

            </Row>
      </Card>

      
    )
}

export default CampDetails