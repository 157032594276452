import React, {useState} from 'react'
import Alert from "react-bootstrap/Alert"
import {Button} from "react-bootstrap"

function ErrorPage() {
    const [show, setShow] = useState(true)


    return (
        <div>
        <Alert show={show} variant = "success" onClose={() => setShow(false)} dismissible>
            <Alert.Heading>Oops ! Something went wrong..</Alert.Heading>
            <hr />
            <p>
                <ul className="list-group-flush">
                    <li>
                        Check if url entered is correct
                    </li>
                    <li>
                        Check your Internet connection
                    </li>
                </ul>
            </p>
            
        </Alert>
        {!show && <Button onClick = {() =>  window.location.reload()}>Try Again</Button>}

        </div>

    )
}

export default ErrorPage
