import React,{useState, useContext} from "react"
import {Input, AutoComplete, Row, Col, Button} from "antd"
import jsonQ from "jsonq"
import {SearchOutlined, SyncOutlined} from "@ant-design/icons"
import {Colors }from "../../Colors"
import UserCard from "../header/UserCard"
import CampDetails from "./CampDetails"
import {ListContext} from "../../ListContext"
import {useMediaQuery} from "react-responsive"


const mockVal = (str, data) => {  //function to check searched string with json data and return new array with refined names
    let suggestion = ""
    let newjson = []
    //let arr = data.map((item) => item.username)[0].toString()
    //console.log(arr)
  for(var i =0; i< data.length; i++){
       let arr = data.map((item) => item.display_name)[i].toString()
        if(arr.toUpperCase().includes(str.toUpperCase())){
            suggestion = arr
           newjson.push({value: suggestion})
           
        }
        //console.log(newjson)
    }
    
    return newjson
}
    

function Header(props){
    const isMobile = useMediaQuery({
        query: '(max-width: 500px)'
      })


    const {data, load, isEmbed} = useContext(ListContext)//import api data
    const [dataValue, setdataValue] = data
    const [loadValue, setloadValue] = load
    const [isEmbedValue, setIsEmbedValue] = isEmbed
    const [value, setValue] = useState('')
    const [options, setOptions] = useState([])   
    const [searching, setSearch] = useState(false)
    //console.log(options)

  
    //const v = [{value: 'ash'}, {value: 'bss'}, {value: 'gaf'}]

    function validator(point, data){ //function returns the position of incoming scores & allows for future conditions
        let sdata = jsonQ(data)
         let filtered_data = sdata.find('score')
        let arr = filtered_data.value()
        let index_arr = arr.indexOf(point) + 1
        return index_arr
      
      }
    
const onSearch = searchText => {
    setOptions(
        !searchText ? []: mockVal(searchText, dataValue))
}

const onSelect = d => {
    setIsEmbedValue(true)  
    setValue(d)
    //console.log('OnSelect', d) 
    setSearch(false)
 
}

const onChange = d => {
    setSearch(true)

}

return (
    <div style={{backgroundImage: 'Linear-gradient(to bottom, #008384 0% ,#0D6162 100%)', margin:'2px', padding: '2px', borderRadius: '6px'}}>
    <Row gutter={4} align="center" justify="center">
        <Col xxl={{span:7, offset:1,order:1}} xs={{span:24}} xl={{span:6,order:1}} md={{span:8, order:1}} className="d-flex align-items-center m-2">
            <AutoComplete 
                options={options}
                style={isMobile ? {
                    width: 290, marginTop:8 ,  marginLeft: 10, marginBottom:8
                    
                }:{
                    width: 300
                    
                }}
                onSelect={onSelect}
                onSearch={onSearch}  
                onChange={onChange}
                placeholder= {<span style={{letterSpacing: '2px'}}>Search with username</span>}
                notFoundContent={<span>username not found</span>}
                
            >

                
            </AutoComplete>

            <Button  shape="square" icon={searching ? <SyncOutlined spin style={{color:'#FFFF'}}/> : <SearchOutlined style={{color: "#FFFF", fontSize:'1.4rem'}}/>    } 
            style={{backgroundColor:`${Colors.secondary}`, border:'none', width:'3.4rem'}}
             
             />
        </Col>
       <Col xxl={{span:5,order:2}} xl={{span:5, offset:1,order:2}}  xs={{span:24}} md={{span:12,  order:4}}>
            { isEmbedValue &&
                dataValue.map((item) => {
                    if(item.display_name === value)
                    return ( 
                       <UserCard 
                           name={item.display_name}
                           score={item.score}
                           rank={validator(item.score, dataValue)}
                       /> 
                               
                    )} )
             }       
       </Col>
       <Col xxl={{span:3, offset:1,order:3}} xl={{span:3, offset:3,order:3}} xs={{span:11}} md={{span:6, order:2}}>
             <CampDetails 
                 title="Team"
                 description="Campaign"
             />
       </Col>
       <Col  xxl={{span:3,order:4}} xl={{span:4,order:4}} xs={{span:11,et:1}} md={{span:6, offset:1,order:3}}>
            <CampDetails 
                 title="Total Members"
                 description={dataValue.length}
            />
       </Col>
 
     </Row>
     </div>
)

}

export default Header
