import React from 'react'
import {BrowserRouter, Route, Switch} from "react-router-dom"
import Main from './Leaderboard/Main'
import "./App.less"
import {DataProvider} from "./Leaderboard/ListContext"

function App() {
   // const { pathname } = useLocation()
    //var get_url = window.location.pathname //get url from window bar
    //var data_slicer = get_url.slice(11,) //removes base page    
   // var data_campaign = data_slicer.split("/")
    //console.log(data_campaign)
    return (
        <DataProvider>
            <div>
                <BrowserRouter>
                  
                    <Route path="/"
                     component={Main}
                      />

                </BrowserRouter>
        
            </div>
        </DataProvider>
    )
}



export default App
/*      <BrowserRouter >

                    <Route path="/dashboard"
                     component={
                         data_campaign.length === 2 && data_campaign[1] != 0 ? dashboard 
                         : data_campaign.length === 1 && data_campaign[0] != 0 ? leaderboard
                         : errorPage            
                     }
                      />

            </BrowserRouter>
*/
